import api from '@/services/api'

export default {
  getAll: () => api.get('pages'),
  getPageByUrl: (route) => api.get(
    'pages/getByRoute', {
      params: { route },
    },
  ),
}
