<template>
  <div class="content-cards-modules">
    <box
      v-for="item in keys"
      :key="item"
    >
      <div class="page-list">
        <h2>{{ item }}</h2>
        <ul>
          <li
            v-for="(page, index) in modules[item]"
            :key="index"
          >
            <router-link
              :to="{ path: `/paginas${page.route}` }"
            >
              {{ page.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </box>
  </div>
</template>

<script>
export default {
  name: 'BoxListPages',
  props: {
    pages: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return { }
  },
  computed: {
    modules() {
      return this.groupBy(this.pages, 'module')
    },
    keys() {
      return Object.keys(this.modules)
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    groupBy(arr, property) {
      return arr.reduce((memo, x) => {
        if (!memo[x[property]]) {
          memo[x[property]] = []
        }
        memo[x[property]].push(x)

        return memo
      }, {})
    },
    /* eslint-enable no-param-reassign */
  },
}
</script>

<style lang="sass" scoped>
.content-cards-modules
  display: flex
  flex-wrap: wrap

  .sas-box
    min-width: 30%
    margin-right: 1%
    margin-bottom: 16px

    h2
      font-size: 18px
      margin-bottom: 16px

    ul
      li
        margin: 8px 0
        a
          padding: 8px 0
</style>
