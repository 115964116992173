<template>
  <div class="home">
    <section class="home-body">
      <MainHeader
        description="Aplicação de documentos por categorias"
        module=""
        page-title="Documentos"
      />
      <wrapper class="content-home">
        <h1 class="title-page">
          Módulos de documentos
        </h1>

        <BoxListPages
          v-if="pages.length > 0"
          :pages="pages"
        />
      </wrapper>
    </section>
  </div>
</template>

<script>
import MainHeader from '@/components/shared/MainHeader.vue'
import BoxListPages from '@/components/Pages/BoxListPages.vue'

import pageService from '@/services/pageService'

export default {
  name: 'Home',
  components: {
    BoxListPages,
    MainHeader,
  },
  data() {
    return {
      pages: [],
    }
  },
  async created() {
    const { data } = await pageService.getAll()
    this.pages = data
  },
}
</script>

<style lang="sass" scoped>
.home
  box-sizing: border-box
  color: $color-ink

.home-body
  .content-home
    padding: 32px 0
    display: flex
    flex-flow: column

    .title-page
      padding: 16px 0
      font-size: 22px
</style>
